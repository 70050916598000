import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function BlogMenu() {
  const data = useStaticQuery(graphql`
    query BlogMenuLinks {
      allMdx(filter: { frontmatter: { tags: { eq: "content" } } }) {
        edges {
          node {
            frontmatter {
              category
            }
          }
        }
      }
    }
  `);

  const getCategories = (items) => {
    let categoryItems = items.map((item) => {
      return item.node.frontmatter.category;
    });
    let uniqueCategories = new Set(categoryItems);
    let categories = Array.from(uniqueCategories);
    categories = ["all posts", ...categories];
    return categories;
  };

  const getUrls = (items) => {
    let categoryItems = items.map((item) => {
      return item.node.frontmatter.category;
    });
    let lowercaseCats = categoryItems.map((category) => category.toLowerCase());
    let uniqueCategories = new Set(lowercaseCats);
    let categories = Array.from(uniqueCategories);
    categories = ["", ...categories];
    let urls = Array.from(categories);
    return urls;
  };

  const state = {
    categories: getCategories(data.allMdx.edges),
    urls: getUrls(data.allMdx.edges),
  };

  return (
    <Nav
      activeKey={
        typeof window !== "undefined" ? window.location.pathname : null
      }
      defaultActiveKey={`/content`}
      className="flex-column"
    >
      {state.categories.map((link, index) => (
        <Nav.Item key={index++}>
          <Link
            to={`/content/${state.urls[index - 1]}`}
            style={{ textDecoration: "none" }}
          >
            <Nav.Link
              as="span"
              eventKey={
                link === "all posts"
                  ? `/content`
                  : `/content/${state.urls[index - 1]}`
              }
              className="content-category m-2"
            >
              {link}
            </Nav.Link>
          </Link>
        </Nav.Item>
      ))}
    </Nav>
  );
}
