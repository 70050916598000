import React, { Component } from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogMenu from "./blogMenu";

const getCategories = (items) => {
  let categoryItems = items.map((item) => {
    return item.node.frontmatter.category;
  });
  let uniqueCategories = new Set(categoryItems);
  let categories = Array.from(uniqueCategories);
  categories = ["all posts", ...categories];
  return categories;
};

const activeButtonClass = {
  backgroundColor: "transparent",
  color: "#0078ae",
  borderColor: "#0078ae",
};

class BlogItems extends Component {
  state = {
    items: this.props.items.allMdx.edges,
    blogPostItems: this.props.items.allMdx.edges,
    categories: getCategories(this.props.items.allMdx.edges),
    selectedItem:
      getCategories(this.props.items.allMdx.edges) &&
      getCategories(this.props.items.allMdx.edges)[0],
  };
  handleItems = (category) => {
    if (category === "all posts") {
      this.setState({
        blogPostItems: [...this.state.items],
        selectedItem: category,
      });
    } else {
      this.setState({
        blogPostItems: [
          ...this.state.items.filter((edge) => {
            return edge.node.frontmatter.category === category;
          }),
        ],
        selectedItem: category,
      });
    }
  };
  render() {
    return (
      <Container fluid="lg">
        <Row className="my-xl-5 my-4 py-xl-5 py-4">
          <Col lg={3} className="mb-4 mb-xl-0">
            <BlogMenu />
          </Col>
          <Col lg={9} className="content-list">
            {this.state.blogPostItems.map((edge) => {
              return (
                <span key={edge.node.id}>
                  <Row className="align-items-center">
                    <Col
                      lg={4}
                      xl={3}
                      className="pe-xl-4 mb-4 mb-lg-0 img-container"
                    >
                      <img
                        src={edge.node.frontmatter.contentThumb}
                        alt={`${edge.node.headline} thumbnail`}
                        className="img-fluid"
                      />
                    </Col>
                    <Col lg={8} xl={9}>
                      <Row className="align-items-center mb-3">
                        <Col xs={6} className="text-start">
                          <p>
                            <span className="category-text">
                              {edge.node.frontmatter.category}
                            </span>
                          </p>
                        </Col>
                        <Col xs={6} className="text-end">
                          <p>
                            <span className="date-created">
                              {edge.node.frontmatter.dateCreated}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <h3>{edge.node.frontmatter.headline}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Link
                            to={`/${edge.node.slug}/`}
                            className="styled-link"
                          >
                            Read More{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="13"
                              viewBox="0 0 8 13"
                              fill="none"
                            >
                              <path
                                d="M1.85101 0.5L7.85107 6.5L1.85101 12.5L0.148961 10.7978L4.44685 6.5L0.148961 2.20217L1.85101 0.5Z"
                                fill="#0078AE"
                              />
                            </svg>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                </span>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BlogItems;
